.form-box {
  .form-content {
    margin-top: 10px;

    .top {
      display: grid;
      grid-template-columns: repeat(auto-fill, 45%);
      column-gap: 5%;

      .ccNumber {
        display: grid;
        grid-template-columns: repeat(auto-fill, calc((100% - 6%) / 3));
        column-gap: 3%;
      }

      .required-label {
        font-size: 15px;
        line-height: 1.799;
        color: $primary;
        text-transform: uppercase;
        padding: 0 0 10px;

        img {
          margin-bottom: 4px;
          cursor: pointer;
          position: relative;
        }
      }

      .label-icon:hover + .hoverShow {
        display: flex !important;
        align-items: center;
        justify-content: center;
        top: 35px;
      }

      /deep/ .is-error .required-label {
        color: $light-red !important;
      }
    }

    .bottom {
      display: grid;
      grid-template-columns: repeat(auto-fill, 45%);
      column-gap: 5%;

      .left {
        .date {
          display: grid;
          grid-template-columns: repeat(auto-fill, calc((100% - 3%) / 2));
          column-gap: 3%;
        }

        .security {
          /deep/ .el-form-item__content {
            line-height: 1;
          }
          .cvvTip {
            font-size: 12px;
            color: rgb(98, 109, 113);
          }
        }

        .btn-blue {
          width: 100%;
          margin-top: 10px;
        }
      }

      .right {
        margin: 40px 0 20px;
      }
    }
  }

  .upgrade {
    color: $light-red !important;
    margin-bottom: 15px;
    font-size: 20px !important;
  }
}

@media (max-width: 767px) {
  .form-box {
    .form-content {
      .top,
      .bottom {
        grid-template-columns: repeat(auto-fill, 100%);

        .right {
          margin-top: 0;
          margin-bottom: 20px;
        }
      }
    }
  }
}
